<template>
    <div id="tab4" class="tab-content">
        <Calculator/>
        <section class="blockElement space pt-0 bg-white forexTool-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12 col-lg-10">
                        <div class="bg-white boxedShadow">
                            <div class="topRadius">
                                <h1 class="f-30 semibold line-1">{{$t('calculator.text72')}}</h1>
                                <p>{{$t('calculator.text73')}}</p>
                                 <Form class="allCalculator mt-md-5" @submit="currencysubmit">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text74')}}</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="amount"
                                                autofill="off"
                                                v-model="form.amount"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="amount" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text75')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="from"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.currencyFrom"
                                                >
                                                <option v-for="item,key in currencyList" :key="key" :value="item.name">{{item.text}}</option>
                                                </Field>
                                                <ErrorMessage name="from" class="text-danger" />
                                            </div>
                                        </div>
                                         <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text76')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="to"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.currencyTo"
                                                >
                                                   <option v-for="item,key in currencyList" :key="key" :value="item.name">{{item.text}}</option>
                                                </Field>
                                                <ErrorMessage name="to" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12 text-center mt-3">
                                            <button type="submit" class="button fillSpiner fillBtn zulu_btn px-md-5">
                                                <Spinner class="buttonSpiner" v-if="loadingCalculator"></Spinner>{{$t('calculator.text31')}}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="getResult bottomRadius">
                                <ul class="resultList d-flex align-items-center flex-wrap mb-0">
                                    <li>
                                        <p class="f-30 mb-2">{{response.rate || '0.00'}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text32')}}</p>
                                    </li>
                                    <li>
                                        <p class="f-30 mb-2">{{response.value || 0}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text33')}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('calculator.text12')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample3">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('calculator.text77')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample3">
                                    <div class="accordion-body">{{$t('calculator.text78')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    {{$t('calculator.text79')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample3">
                                    <div class="accordion-body">{{$t('calculator.text80')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    {{$t('calculator.text81')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample3">
                                    <div class="accordion-body">{{$t('calculator.text82')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                   {{$t('calculator.text83')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample3">
                                    <div class="accordion-body">{{$t('calculator.text84')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        {{$t('calculator.text85')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample3">
                                    <div class="accordion-body">{{$t('calculator.text86')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store.ts";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import Calculator from "@/views/markets/calculator/calculator"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                loadingCalculator: false,
                response: {},
                currencyList : [],
                form: {
                    amount: 1,
                    currencyFrom: '',
                    currencyTo: '',
                },
                removeCurrency : ['BRL','CLP','CNY','ILS','INR','KRW','RON','RUB','TWD']
            }
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            Calculator
        },
        methods:{
            currencysubmit(){
                this.store.currencycalculate(this.form, true, this).then(response => {
                    this.loading = false
                    this.response = response
                });
            }
        },
        computed: {
            isActive() {
                return false
            }
        },
        created(){
            this.store.getConverterCurrencies({},true).then(response =>{
                if(response.length){
                    this.currencyList = response.filter((i)=> { 
                        return !this.removeCurrency.includes(i.name);
                    })
                    this.form.currencyFrom = response[0].name
                    this.form.currencyTo = response[0].name

                }
            })
        }
    }
</script>